import { HashRouter } from "react-router-dom";
import DigitalCompletionTool from "../DigitalCompletionTool/DigitalCompletionTool";
import "../../services/i18n";

function WebComponentWrapper() {
  return (
    <HashRouter>
      <DigitalCompletionTool />
    </HashRouter>
  );
}

export default WebComponentWrapper;
