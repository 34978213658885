
      var exported = {};

      import API from "!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../../node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      
      import content, * as namedExport from "!!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./Buttons.scss";
      
      if (content && content.locals) {
              exported.locals = content.locals;
            }
            

var refs = 0;
var update;
var options = {};

options.styleTagTransform = function (css, style, options) {
          const parent = options?.target || document.head;
          style.innerHTML = css;
          parent.appendChild(style);
        };
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

exported.use = function(insertOptions) {
  options.options = insertOptions || {};

  if (!(refs++)) {
    update = API(content, options);
  }

  return exported;
};
exported.unuse = function() {
  if (refs > 0 && !--refs) {
    update();
    update = null;
  }
};



export * from "!!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./Buttons.scss";
       export default exported;
