import Styled from "../Styled/Styled";
import styles from "./WelcomeButton.css";

interface WelcomeButtonProps {
  url: string;
  buttonText: string;
  buttonIcon?: string;
  buttonClass: string;
}

function WelcomeButton({
  url,
  buttonText,
  buttonIcon,
  buttonClass,
}: WelcomeButtonProps) {
  return (
    <Styled styles={styles}>
      <a data-am-button={buttonClass} className="wide button" href={url}>
        {buttonIcon ? (
          <>
            <div className="icon-wrapper">
              <img src={buttonIcon} className="button-icon" alt="login"></img>
            </div>
            <div className="text-wrapper">{buttonText}</div>
          </>
        ) : (
          <>{buttonText}</>
        )}
      </a>
    </Styled>
  );
}

export default WelcomeButton;
