import { FC, useEffect, useRef, createElement } from "react";

interface StyledProps {
  styles: any;
  children: JSX.Element;
  className?: string;
}

const Styled: FC<StyledProps> = ({ styles, className, children }) => {
  const styleParentRef = useRef(null);

  useEffect(() => {
    if (
      styleParentRef &&
      styleParentRef.current &&
      typeof styles.use === "function"
    ) {
      styles.use({ target: styleParentRef.current });
      return styles.unuse;
    }
  }, [styles, styleParentRef]);

  const styleParent = createElement(
    "div",
    { ref: styleParentRef, className: className },
    children
  );

  return styleParent;
};

export default Styled;
