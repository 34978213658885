import SketchCanvas from "../SketchCanvas/SketchCanvas";

interface SketchAccidentProps {
  templateType: string | undefined;
  idHash: string | undefined;
}

function SketchAccident({ templateType, idHash }: SketchAccidentProps) {
  return (
    <div className="outer-wrapper">
      <SketchCanvas templateType={templateType} idHash={idHash} />
    </div>
  );
}

export default SketchAccident;
