function FullscreenSpinner() {
  return (
    <div data-am-spinner="overlay">
      <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66">
        <circle
          className="spinner-stroke"
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </div>
  );
}

export default FullscreenSpinner;
