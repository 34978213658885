import { useState, useEffect } from "react";
import { debounce } from "../services/utilities";

export function useWindowDimension() {
  const [dimension, setDimension] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      setDimension([window.innerWidth, window.innerHeight]);
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight}px`
      );
    }, 10);
    window.addEventListener("resize", debouncedResizeHandler);
    debouncedResizeHandler();
    return () => window.removeEventListener("resize", debouncedResizeHandler);
  }, []);
  return dimension;
}
