import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import deleteIcon from "../../img/trashcan.svg";

interface DeleteButtonProps {
  deleteFn: () => void;
}

export interface DeleteButtonRef {
  onDragMove: (target: HTMLElement) => void;
  onDrop: () => void;
}

const DeleteButton = forwardRef<DeleteButtonRef, DeleteButtonProps>(
  ({ deleteFn }: DeleteButtonProps, ref) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [active, setActive] = useState(false);
    const { t } = useTranslation();

    const onDrop = useCallback(() => {
      if (active) {
        deleteFn();
        setActive(false);
      }
    }, [deleteFn, active]);

    const onDragMove = useCallback(
      (target: HTMLElement) => {
        if (buttonRef?.current) {
          if (buttonRef.current.contains(target)) {
            setActive(true);
            return;
          }
        }

        setActive(false);
      },
      [buttonRef]
    );

    useImperativeHandle(ref, () => ({
      onDrop,
      onDragMove,
    }));

    return (
      <button
        ref={buttonRef}
        onClick={deleteFn}
        className={"delete-button bg-accent" + (active ? " active" : "")}
      >
        <img
          src={deleteIcon}
          className="delete-button-icon"
          alt={t("Delete")}
        ></img>
        <p className="text-center">{t("Delete")}</p>
      </button>
    );
  }
);

export default DeleteButton;
