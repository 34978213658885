import { UserInfo } from "../models/user";

export async function fetchUserId(): Promise<UserInfo> {
  const url = `user`;

  const response = await sendRequest(url, "GET");
  return response.json();
}

export async function checkId(id: string | null): Promise<boolean> {
  const url = `Claim/id/${id ?? ""}`;

  try {
    await sendRequest(url, "GET");
    return true;
  } catch (statusCode) {}
  return false;
}

export async function submit(
  id: string,
  language: string,
  canvasImage: string,
  description: string
): Promise<Response> {
  const url = `Claim/Submit`;

  return await sendRequest(
    url,
    "POST",
    JSON.stringify({
      id,
      language,
      canvasImage,
      description,
    })
  );
}

async function sendRequest(
  path: string,
  method: string,
  jsonBody?: string,
  file?: File
): Promise<Response> {
  let baseUrl = `${window.location.origin}${window.location.pathname}`;

  if (window.location.hostname === "localhost") {
    baseUrl = "https://localhost:7242/";
  } else if (window.location.hostname === "bs-local.com") {
    baseUrl = "https://bs-local.com:7242/";
  } else if (baseUrl.slice(-1) !== "/") {
    baseUrl += "/";
  }

  const url = new URL(`${baseUrl}${path}`);
  const request = new Request(url.toString());
  appendUniversalHeaders(request);

  let body = jsonBody as BodyInit;
  if (file) {
    request.headers.delete("Content-Type");
    body = new FormData();
    body.append(file.name, file, file.name);
  }

  const response = await fetch(request, {
    method,
    body: body || undefined,
    credentials: "include",
  });

  if (response.ok) {
    return response;
  }

  throw response.status;
}

function appendUniversalHeaders(request: Request) {
  request.headers.append("Cache-Control", "no-store, no-cache");
  request.headers.append("Content-Type", "application/json");
}
