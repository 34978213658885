import { useState } from "react";
import ToolBarContent from "./ToolBarContent";
import plusVolviaSvgImage from "../../img/plusVolvia.svg";
import { useTranslation } from "react-i18next";
import { IconImageProps } from "../SketchCanvas/Images";
import styles from "./ToolBarModal.scss";
import Styled from "../Styled/Styled";

interface ToolBarManagerProps {
  onClick: Function;
  onDrag: Function;
}

function ToolBarManager({ onClick, onDrag }: ToolBarManagerProps) {
  const { t } = useTranslation();
  const [mobileToolbarIsOpen, setMobileToolbarIsOpen] = useState(false);

  const toggleToolBar = () => {
    setMobileToolbarIsOpen(!mobileToolbarIsOpen);
  };

  const handleClick = (icon: IconImageProps) => {
    toggleToolBar();
    onClick(icon);
  };

  return (
    <Styled styles={styles} className="mobile-toolbar">
      <>
        <button
          className="toolbar-button bg-secondary-light open-modal-button"
          onClick={toggleToolBar}
        >
          <img
            src={plusVolviaSvgImage}
            className="button-icon"
            alt={t("open modal with images")}
          ></img>
        </button>
        <div
          data-testid="toolbar-manager-test"
          className={mobileToolbarIsOpen ? "modal-is-open" : "modal-is-closed"}
        >
          <div data-am-modal data-am-accordion>
            <div className="modal-container" onClick={toggleToolBar}>
              <div
                className="modal-dialog mobile-modal"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="close-modal-button-wrapper">
                  <button className="btn-close-modal" onClick={toggleToolBar}>
                    <i
                      className="icon-cancel no-margin"
                      aria-label={t("close modal")}
                    ></i>
                  </button>
                </div>
                <ToolBarContent
                  onClick={handleClick}
                  onDrag={onDrag}
                  idSuffix="_kajsa"
                ></ToolBarContent>
              </div>
            </div>
          </div>
        </div>
      </>
    </Styled>
  );
}

export default ToolBarManager;
