import React from "react";
import { Image } from "react-konva";
import useImage from "use-image";
import { TemplateImageName } from "./Images";

interface TemplateImageProps {
  image: any;
  size: number;
  templateType: string | undefined;
}

function TemplateImage({ image, size, templateType }: TemplateImageProps) {
  const [img] = useImage(image);

  return (
    <Image
      image={img}
      width={size}
      height={size}
      templateType={templateType}
      listening={false}
      name={TemplateImageName}
    />
  );
}

export default TemplateImage;
