import { createContext } from "react";

export interface BrandState {
  canvasBackgroundColor: string | undefined;
  brandName: string | undefined;
  backgroundImage: string | undefined;
}

const initialState: BrandState = {
  canvasBackgroundColor: undefined,
  brandName: undefined,
  backgroundImage: undefined,
};

export const BrandContext = createContext<BrandState>(initialState);
