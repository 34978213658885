/**
 * This file is the entry point for the web component build output.
 */
import React from "react";
import ReactDOM from "react-dom";
import ReactToWebComponent from "react-to-webcomponent";
import WebComponentWrapper from "../components/WebComponentWrapper/WebComponentWrapper";
import importedStyles from "../imports.css";

class DigitalCompletionToolWithStyles extends ReactToWebComponent(
  WebComponentWrapper,
  React,
  ReactDOM,
  {
    shadow: "open",
  }
) {
  connectedCallback() {
    super.connectedCallback();

    // Fonts don't get loaded unless they are manually imported outside of the shadow dom.
    // This is a good place to do that import.
    importedStyles.use();
  }
}

customElements.define(
  "digital-completion-tool",
  DigitalCompletionToolWithStyles
);
