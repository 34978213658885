import getImages from "../SketchCanvas/Images";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";

interface ToolBarContentProps {
  onClick: Function;
  onDrag: Function;
  idSuffix?: string;
}

function ToolBarContent({ onClick, onDrag, idSuffix }: ToolBarContentProps) {
  const { t } = useTranslation();
  const images = getImages(t);
  const firstCategoryString = images[0].label;
  const sessionStorageKey = "firstCategoryOpened";
  const reactRef = useRef<any>();

  useEffect(() => {
    // Check if first category was already automatically expanded during this session. If not, expand it.
    const sessionStorageValue = sessionStorage.getItem(sessionStorageKey);
    if (!sessionStorageValue) {
      sessionStorage.setItem(sessionStorageKey, "true");
      setOpenCategory(firstCategoryString);
    }
  }, [firstCategoryString]);

  const [openCategory, setOpenCategory] = useState("");

  const scrollContentToTop = (currentRef: any) => {
    currentRef?.current.scroll({
      top: 0,
    });
  };

  const toggleOpenCategory = (id: string) => {
    // This scrolls content inside toolbar categories to the top when category is closed. The timeout is necessary to make it scroll before the content is unrendered.
    setTimeout(() => {
      setOpenCategory(openCategory !== id ? id : "");
      if (openCategory !== "" && reactRef) {
        let tempRef = reactRef;
        scrollContentToTop(tempRef);
      }
    }, 0);
  };

  return (
    <ul className="accordion-list toolbar" data-am-list>
      {images.map((category, index) => (
        <li key={index}>
          <input
            type="checkbox"
            name="accordion"
            className="checkbox"
            id={category.label + idSuffix}
            checked={openCategory === category.label}
            onChange={() => {
              toggleOpenCategory(category.label);
            }}
          />
          <label
            htmlFor={category.label + idSuffix}
            className="accordion-header modal-label"
          >
            {category.label}
          </label>
          <div
            className="accordion-body toolbar"
            ref={openCategory === category.label ? reactRef : null}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: category.description,
              }}
            ></span>
            <div data-am-content className="accordion-container">
              {category.subGroup.map((subGroup, index) => (
                <div key={index}>
                  <p>
                    <strong>{subGroup.subGroupName}</strong>
                  </p>
                  {subGroup.icons.map((icon, innerIndex) => (
                    <button
                      className="toolbar-image-button"
                      onClick={() => {
                        onClick(icon);
                      }}
                      onDrag={() => {
                        onDrag(icon);
                      }}
                      key={innerIndex}
                    >
                      <img
                        src={icon.srcTooltip ?? icon.src}
                        className="button-icon"
                        alt={icon.name}
                      ></img>
                      {subGroup.showLabel ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: icon.name,
                          }}
                        ></p>
                      ) : (
                        <p />
                      )}
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default ToolBarContent;
