import styles from "./BackgroundBox.scss";
import Styled from "../Styled/Styled";

interface BackgroundBoxProps {
  children?: JSX.Element | JSX.Element[];
}

function BackgroundBox({ children }: BackgroundBoxProps) {
  return (
    <Styled styles={styles}>
      <div className="background-box bg-accent">{children}</div>
    </Styled>
  );
}

export default BackgroundBox;
