import React from "react";
import ToolBarContent from "./ToolBarContent";

interface StationaryToolBarProps {
  onClick: Function;
  onDrag: Function;
}

function StationaryToolBar({ onClick, onDrag }: StationaryToolBarProps) {
  return (
    <div className="desktop-toolbar">
      <div data-testid="desktop-toolbar-test" className="modal-is-open">
        <div className="modal-container">
          <div data-am-accordion>
            <ToolBarContent onClick={onClick} onDrag={onDrag}></ToolBarContent>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StationaryToolBar;
