import React from "react";
import getImages, { MaxCanvasSize } from "./Images";
import getTemplates from "../../services/templates";
import TemplateImage from "./TemplateImage";
import SketchImage from "./SketchImage";
import { getFromLocalStorage } from "../../services/localStorage";
import { TFunction } from "react-i18next";
function matchTemplate(t: TFunction, templateType: string | undefined) {
  return getTemplates(t)
    .flatMap((category) => category.subGroups)
    .flatMap((subgroup) => subgroup.templates)
    .find((template) => template.templateType === templateType);
}

export function getTemplateForCanvas(
  setTemplate: Function,
  templateType: string | undefined,
  canvasSize: number,
  t: TFunction,
  idHash: string | undefined
) {
  var canvasJson = getFromLocalStorage("canvasJson", idHash);
  const matchingTemplate = matchTemplate(t, templateType);
  let selectedTemplate = matchingTemplate;

  if (!matchingTemplate && canvasJson !== null) {
    var stageJson = JSON.parse(canvasJson);
    let jsonTemplateType = stageJson.children[0].children[1].attrs.templateType;

    selectedTemplate = matchTemplate(t, jsonTemplateType);
  }

  setTemplate(
    <TemplateImage
      image={selectedTemplate?.image}
      size={canvasSize}
      templateType={selectedTemplate?.templateType}
    />
  );
}

export function getImagesForCanvas(
  setImage: Function,
  select: Function,
  onDragMove: (target: HTMLElement) => void,
  onDragEnd: () => void,
  newScale: number,
  t: TFunction,
  idHash: string | undefined
) {
  var canvasJson = getFromLocalStorage("canvasJson", idHash);
  if (canvasJson !== null) {
    const stageJson = JSON.parse(canvasJson);

    const previousCanvasSize = Math.min(
      stageJson.attrs.width,
      stageJson.attrs.height
    );
    const previousScale = previousCanvasSize / MaxCanvasSize;

    setImage(
      stageJson.children[1].children
        .slice(0, -2)
        .map((image: any, index: number) => {
          const matchingIcon = getImages(t)
            .flatMap((category) => category.subGroup)
            .flatMap((subGroup) => subGroup.icons)
            .find((icon) => icon.iconType === image.attrs.iconType);
          return (
            <SketchImage
              image={matchingIcon?.src}
              key={index}
              onClick={select}
              onDragMove={onDragMove}
              onDragEnd={onDragEnd}
              position={[
                (image.attrs.x / previousScale) * newScale,
                (image.attrs.y / previousScale) * newScale,
              ]}
              rotation={image.attrs.rotation}
              scale={newScale}
              id={image.attrs.id}
              iconType={image.attrs.iconType}
              width={matchingIcon?.customWidth}
            />
          );
        })
    );
  }
}
