import arrow from "../../img/icons/arrows/arrow-straight.svg";
import arrowCanvas from "../../img/icons/arrows/arrow-straight-canvas.svg";
import bikeTooltip from "../../img/icons/vehicles/bike_tooltip.svg";
import bikeCanvas from "../../img/icons/vehicles/bike.svg";
import busTooltip from "../../img/icons/vehicles/bus_tooltip.svg";
import busCanvas from "../../img/icons/vehicles/bus.svg";
import vanTooltip from "../../img/icons/vehicles/van_tooltip.svg";
import vanCanvas from "../../img/icons/vehicles/van.svg";
import myVanCanvas from "../../img/icons/vehicles/van_with_cross.svg";
import carTooltip from "../../img/icons/vehicles/car_tooltip.svg";
import carCanvas from "../../img/icons/vehicles/car.svg";
import myCarCanvas from "../../img/icons/vehicles/car_with_cross.svg";
import badgerCanvas from "../../img/icons/animals/badger.svg";
import badgerTooltip from "../../img/icons/animals/badger_tooltip.svg";
import catCanvas from "../../img/icons/animals/cat.svg";
import catTooltip from "../../img/icons/animals/cat_tooltip.svg";
import deerCanvas from "../../img/icons/animals/deer.svg";
import deerTooltip from "../../img/icons/animals/deer_tooltip.svg";
import dogCanvas from "../../img/icons/animals/dog.svg";
import dogTooltip from "../../img/icons/animals/dog_tooltip.svg";
import mooseCanvas from "../../img/icons/animals/moose.svg";
import wildBoarCanvas from "../../img/icons/animals/wild-boar.svg";
import wildBoarTooltip from "../../img/icons/animals/wild-boar_tooltip.svg";
import leftTurn from "../../img/icons/arrows/arrow-turn-left.svg";
import leftTurnCanvas from "../../img/icons/arrows/arrow-turn-left-canvas.svg";
import laneChangeLeft from "../../img/icons/arrows/arrow-lane-change-left.svg";
import laneChangeLeftCanvas from "../../img/icons/arrows/arrow-lane-change-left-canvas.svg";
import laneChangeRight from "../../img/icons/arrows/arrow-lane-change-right.svg";
import laneChangeRightCanvas from "../../img/icons/arrows/arrow-lane-change-right-canvas.svg";
import mcTooltip from "../../img/icons/vehicles/mc_tooltip.svg";
import mcCanvas from "../../img/icons/vehicles/mc.svg";
import mopedTooltip from "../../img/icons/vehicles/moped_tooltip.svg";
import mopedCanvas from "../../img/icons/vehicles/moped.svg";
import rightTurn from "../../img/icons/arrows/arrow-turn-right.svg";
import rightTurnCanvas from "../../img/icons/arrows/arrow-turn-right-canvas.svg";
import truckTooltip from "../../img/icons/vehicles/truck_tooltip.svg";
import truckCanvas from "../../img/icons/vehicles/truck.svg";
import uturnArrowSvgImage from "../../img/icons/arrows/arrow-u-turn.svg";
import uturnArrowSvgImageCanvas from "../../img/icons/arrows/arrow-u-turn-canvas.svg";
import signInterweaving from "../../img/icons/road-signs/interweaving.svg";
import signInterweavingCanvas from "../../img/icons/road-signs/interweaving-canvas.svg";
import signMainRoad from "../../img/icons/road-signs/main-road.svg";
import signMainRoadCanvas from "../../img/icons/road-signs/main-road-canvas.svg";
import signNarrowRoad from "../../img/icons/road-signs/narrow-road.svg";
import signNarrowRoadCanvas from "../../img/icons/road-signs/narrow-road-canvas.svg";
import signObligationToYield from "../../img/icons/road-signs/obligation-to-yield.svg";
import signObligationToYieldCanvas from "../../img/icons/road-signs/obligation-to-yield-canvas.svg";
import signObligationToYieldOncomTraffic from "../../img/icons/road-signs/obligation-to-yield-oncom-traffic.svg";
import signObligationToYieldOncomTrafficCanvas from "../../img/icons/road-signs/obligation-to-yield-oncom-traffic-canvas.svg";
import signOncomingTrafficOblToYield from "../../img/icons/road-signs/oncoming-traffic-obl-to-yield.svg";
import signOncomingTrafficOblToYieldCanvas from "../../img/icons/road-signs/oncoming-traffic-obl-to-yield-canvas.svg";
import signOneWayDirection from "../../img/icons/road-signs/one-way-direction.svg";
import signReductionOfLanes from "../../img/icons/road-signs/reduction-of-lanes.svg";
import signReductionOfLanesCanvas from "../../img/icons/road-signs/reduction-of-lanes-canvas.svg";
import signStopSign from "../../img/icons/road-signs/stop-sign.svg";
import signStopSignCanvas from "../../img/icons/road-signs/stop-sign-canvas.svg";
import collisionTooltip from "../../img/icons/collision/collision_tooltip.svg";
import collisionCanvas from "../../img/icons/collision/collision.svg";
import building from "../../img/icons/objects/building.svg";
import buildingTooltip from "../../img/icons/objects/building_tooltip.svg";
import crosswalk from "../../img/icons/objects/crosswalk.svg";
import crosswalkTooltip from "../../img/icons/objects/crosswalk_tooltip.svg";
import fence from "../../img/icons/objects/fence.svg";
import fenceTooltip from "../../img/icons/objects/fence_tooltip.svg";
import holeCrack from "../../img/icons/objects/hole_crack.svg";
import holeCrackTooltip from "../../img/icons/objects/hole_crack_tooltip.svg";
import hole from "../../img/icons/objects/hole.svg";
import holeTooltip from "../../img/icons/objects/hole_tooltip.svg";
import house from "../../img/icons/objects/house.svg";
import houseTooltip from "../../img/icons/objects/house_tooltip.svg";
import rock from "../../img/icons/objects/rock.svg";
import rockTooltip from "../../img/icons/objects/rock_tooltip.svg";
import trafficLight from "../../img/icons/objects/traffic-light.svg";
import trafficLightTooltip from "../../img/icons/objects/traffic-light_tooltip.svg";
import tree from "../../img/icons/objects/tree.svg";
import treeTooltip from "../../img/icons/objects/tree_tooltip.svg";
import pedestrianTooltip from "../../img/icons/objects/pedestrian_tooltip.svg";
import pedestrianCanvas from "../../img/icons/objects/pedestrian.svg";
import yieldMarking from "../../img/icons/objects/road-marking_vajningsplikt.svg";
import yieldMarkingTooltip from "../../img/icons/objects/road-marking_vajningsplikt_tooltip.svg";
import stopLine from "../../img/icons/objects/road-marking_stop-line.svg";
import stopLineTooltip from "../../img/icons/objects/road-marking_stop-line_tooltip.svg";

interface ImageCategoryProps {
  label: string;
  description: string;
  subGroup: SubGroupProps[];
}

interface SubGroupProps {
  subGroupName?: string;
  icons: IconImageProps[];
  showLabel: boolean;
}

interface IconImageProps {
  name: string;
  iconType: string;
  src: string;
  srcTooltip?: string;
  customWidth?: number;
}

function getImages(t: Function): ImageCategoryProps[] {
  return [
    {
      label: t("Your vehicle"),
      description: t(
        "Choose <strong>your vehicle</strong>, it will be marked with an X."
      ),
      subGroup: [
        {
          showLabel: true,
          icons: [
            {
              name: t("MyCar"),
              iconType: "myCar",
              src: myCarCanvas,
              srcTooltip: carTooltip,
            },
            {
              name: t("MyVan"),
              iconType: "myVan",
              src: myVanCanvas,
              srcTooltip: vanTooltip,
              customWidth: 100,
            },
          ],
        },
      ],
    },
    {
      label: t("Other vehicles"),
      description: t(
        "Choose <strong>other vehicles</strong>. Add more vehicles if more were involved."
      ),
      subGroup: [
        {
          showLabel: true,
          icons: [
            {
              name: t("Car"),
              iconType: "car",
              src: carCanvas,
              srcTooltip: carTooltip,
            },
            {
              name: t("Bus"),
              iconType: "bus",
              src: busCanvas,
              srcTooltip: busTooltip,
              customWidth: 210,
            },
            {
              name: t("Van"),
              iconType: "van",
              src: vanCanvas,
              srcTooltip: vanTooltip,
              customWidth: 100,
            },
            {
              name: t("Bike"),
              iconType: "bike",
              src: bikeCanvas,
              srcTooltip: bikeTooltip,
            },
            {
              name: t("MC"),
              iconType: "mc",
              src: mcCanvas,
              srcTooltip: mcTooltip,
            },
            {
              name: t("Moped"),
              iconType: "moped",
              src: mopedCanvas,
              srcTooltip: mopedTooltip,
            },
            {
              name: t("Truck"),
              iconType: "truck",
              src: truckCanvas,
              srcTooltip: truckTooltip,
              customWidth: 200,
            },
          ],
        },
      ],
    },
    {
      label: t("Direction of travel"),
      description: t(
        "Pick arrows to visualize the <strong>direction of travel</strong> for both yourself and other involved vehicles. If you <strong>changed lane</strong>, please indicate that using the appropriate arrow."
      ),
      subGroup: [
        {
          showLabel: true,
          icons: [
            {
              name: t("leftturnarrow"),
              iconType: "leftturnarrow",
              src: leftTurnCanvas,
              srcTooltip: leftTurn,
            },
            {
              name: t("rightturnarrow"),
              iconType: "rightturnarrow",
              src: rightTurnCanvas,
              srcTooltip: rightTurn,
            },
            {
              name: t("lanechangeleftarrow"),
              iconType: "lanechangeleftarrow",
              src: laneChangeLeftCanvas,
              srcTooltip: laneChangeLeft,
            },
            {
              name: t("lanechangerightarrow"),
              iconType: "lanechangerightarrow",
              src: laneChangeRightCanvas,
              srcTooltip: laneChangeRight,
            },
            {
              name: t("straightahead"),
              iconType: "arrow",
              src: arrowCanvas,
              srcTooltip: arrow,
            },
            {
              name: t("uturntarrow"),
              iconType: "uturnarrow",
              src: uturnArrowSvgImageCanvas,
              srcTooltip: uturnArrowSvgImage,
            },
          ],
        },
      ],
    },
    {
      label: t("Collision"),
      description: t(
        "If there were one or more <strong>collisions</strong> please place this icon on the road where it happened."
      ),
      subGroup: [
        {
          showLabel: true,
          icons: [
            {
              name: t("Collision"),
              iconType: "collision",
              src: collisionCanvas,
              srcTooltip: collisionTooltip,
            },
          ],
        },
      ],
    },
    {
      label: t("Other"),
      description: t(
        "You can use any icons in here to clarify the context and help us better understand the accident."
      ),
      subGroup: [
        {
          showLabel: false,
          subGroupName: t("Road signs"),
          icons: [
            {
              name: t("obligation-to-yield"),
              iconType: "obligation-to-yield",
              src: signObligationToYieldCanvas,
              srcTooltip: signObligationToYield,
            },
            {
              name: t("main-road"),
              iconType: "main-road",
              src: signMainRoadCanvas,
              srcTooltip: signMainRoad,
            },
            {
              name: t("stop-sign"),
              iconType: "stop-sign",
              src: signStopSignCanvas,
              srcTooltip: signStopSign,
            },
            {
              name: t("obligation-to-yield-oncom-traffic"),
              iconType: "obligation-to-yield-oncom-traffic",
              src: signObligationToYieldOncomTrafficCanvas,
              srcTooltip: signObligationToYieldOncomTraffic,
            },
            {
              name: t("oncoming-traffic-obl-to-yield"),
              iconType: "oncoming-traffic-obl-to-yield",
              src: signOncomingTrafficOblToYieldCanvas,
              srcTooltip: signOncomingTrafficOblToYield,
            },
            {
              name: t("one-way-direction"),
              iconType: "one-way-direction",
              src: signOneWayDirection,
            },
            {
              name: t("reduction-of-lanes"),
              iconType: "reduction-of-lanes",
              src: signReductionOfLanesCanvas,
              srcTooltip: signReductionOfLanes,
            },
            {
              name: t("narrow-road"),
              iconType: "narrow-road",
              src: signNarrowRoadCanvas,
              srcTooltip: signNarrowRoad,
            },
            {
              name: t("interweaving"),
              iconType: "interweaving",
              src: signInterweavingCanvas,
              srcTooltip: signInterweaving,
            },
          ],
        },
        {
          showLabel: true,
          subGroupName: t("In traffic"),
          icons: [
            {
              name: t("Pedestrian"),
              iconType: "pedestrian",
              src: pedestrianCanvas,
              srcTooltip: pedestrianTooltip,
            },
            {
              name: t("Traffic light"),
              iconType: "traffic-light",
              src: trafficLight,
              srcTooltip: trafficLightTooltip,
            },
            {
              name: t("Tree"),
              iconType: "tree",
              src: tree,
              srcTooltip: treeTooltip,
            },
            {
              name: t("Crosswalk"),
              iconType: "crosswalk",
              src: crosswalk,
              srcTooltip: crosswalkTooltip,
            },
            {
              name: t("Yield marking"),
              iconType: "yieldmarking",
              src: yieldMarking,
              srcTooltip: yieldMarkingTooltip,
            },
            {
              name: t("Stop line"),
              iconType: "stop_line",
              src: stopLine,
              srcTooltip: stopLineTooltip,
            },
            {
              name: t("Rock"),
              iconType: "rock",
              src: rock,
              srcTooltip: rockTooltip,
            },
            {
              name: t("Hole crack"),
              iconType: "hole_crack",
              src: holeCrack,
              srcTooltip: holeCrackTooltip,
            },
            {
              name: t("Hole"),
              iconType: "hole",
              src: hole,
              srcTooltip: holeTooltip,
            },
            {
              name: t("Building"),
              iconType: "building",
              src: building,
              srcTooltip: buildingTooltip,
            },
            {
              name: t("House"),
              iconType: "house",
              src: house,
              srcTooltip: houseTooltip,
            },
            {
              name: t("Fence"),
              iconType: "fence",
              src: fence,
              srcTooltip: fenceTooltip,
            },
          ],
        },
        {
          showLabel: true,
          subGroupName: t("Animals"),
          icons: [
            { name: t("Moose"), iconType: "moose", src: mooseCanvas },
            {
              name: t("Deer"),
              iconType: "deer",
              src: deerCanvas,
              srcTooltip: deerTooltip,
            },
            {
              name: t("Wild boar"),
              iconType: "wild boar",
              src: wildBoarCanvas,
              srcTooltip: wildBoarTooltip,
            },
            {
              name: t("Badger"),
              iconType: "badger",
              src: badgerCanvas,
              srcTooltip: badgerTooltip,
            },
            {
              name: t("Cat"),
              iconType: "cat",
              src: catCanvas,
              srcTooltip: catTooltip,
            },
            {
              name: t("Dog"),
              iconType: "dog",
              src: dogCanvas,
              srcTooltip: dogTooltip,
            },
          ],
        },
      ],
    },
  ];
}

export default getImages;
export type { IconImageProps };
export const MaxCanvasSize: number = 600;
export const MinCanvasHeight: number = 300;
export const TemplateImageName: string = "TemplateImage";
