import logoutIcon from "../../img/logout-icon.svg";
import ProgressTracker from "../ProgressTracker/ProgressTracker";
import styles from "./Header.scss";
import Styled from "../Styled/Styled";
import Tooltip from "../Tooltip/Tooltip";
import volviaLogo from "../../img/decorative/volvia-logo.svg";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import InstructionPopup from "../InstructionPopup/InstructionPopup";

interface HeaderProps {
  title: string;
  text: string;
  textMobile?: string;
  number: number;
  tooltipText?: string;
  logoutOn: boolean;
}

const logout = () => {
  window.location.href = "/logout";
};

function Header({
  title,
  text,
  textMobile,
  number,
  tooltipText,
  logoutOn,
}: HeaderProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Styled styles={styles}>
      <header className="header">
        <div className="text-center p-y-half">
          <img className="logo-image" src={volviaLogo} alt="volvia logo"></img>

          {logoutOn && (
            <div>
              <button className="logout-desktop" onClick={logout}>
                <img
                  className="logout-icon"
                  src={logoutIcon}
                  alt={t("logout-icon")}
                ></img>
                {t("Log out")}
              </button>
              <button className="logout-mobile" onClick={logout}>
                <img
                  className="logout-icon"
                  src={logoutIcon}
                  alt={t("logout-icon")}
                ></img>
              </button>
            </div>
          )}

          <ProgressTracker step={number} />
          <div className="container">
            <h1 className="heading">{title}</h1>
            <div className="l-inline-block">
              {tooltipText && <Tooltip tooltipText={tooltipText} />}
            </div>
            <div className="text-lighten subheading">
              {textMobile ? (
                <>
                  <p className="only-desktop">{text}</p>
                  <p className="only-mobile">{textMobile}</p>
                </>
              ) : (
                <p>{text}</p>
              )}
            </div>
            {pathname.match(/sketch\/?$/) && (
              <InstructionPopup></InstructionPopup>
            )}
          </div>
        </div>
      </header>
    </Styled>
  );
}

export default Header;
