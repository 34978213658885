import Header from "../Header/Header";
import Buttons from "../Buttons/Buttons";
import editSvgImage from "../../img/edit.svg";
import { Trans, useTranslation } from "react-i18next";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
} from "../../services/localStorage";
import styles from "../Preview/Preview.css";
import Styled from "../Styled/Styled";
import { useCallback, useEffect, useState } from "react";
import * as apiClient from "../../services/api-client";
import FullscreenSpinner from "../FullscreenSpinner/FullscreenSpinner";
import BackgroundBox from "../BackgroundBox/BackgroundBox";
import { useNavigate } from "react-router-dom";

interface PreviewProps {
  idHash: string | undefined;
}

function Preview({ idHash }: PreviewProps) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const canvasImage = getFromLocalStorage("canvasImage", idHash)?.toString();
  const trimmedCanvasImageString = canvasImage?.substring(
    canvasImage.indexOf(",") + 1
  );

  const textDescription = getFromLocalStorage("textDescription", idHash);

  useEffect(() => {
    if (canvasImage && textDescription) {
      setIsValidated(true);
    }
  }, [canvasImage, textDescription]);

  const submit = useCallback(() => {
    setIsLoading(true);
    apiClient
      .submit(
        sessionStorage.getItem("id")!,
        i18n.language,
        trimmedCanvasImageString!,
        textDescription!
      )
      .then(() => {
        setIsLoading(false);
        navigate("/thankyou");
        removeFromLocalStorage("textDescription", idHash);
        removeFromLocalStorage("canvasJson", idHash);
        removeFromLocalStorage("canvasImage", idHash);
      })
      .catch((statusCode) => {
        setIsLoading(false);
        if (typeof statusCode === "number" || statusCode instanceof Number) {
          if (statusCode === 401) {
            navigate("/relogin", { state: "/preview" });
            return;
          }
        }

        setShowErrorModal(true);
      });
  }, [navigate, i18n, textDescription, trimmedCanvasImageString, idHash]);

  if (isLoading) {
    return <FullscreenSpinner />;
  }

  return (
    <Styled className="outer-wrapper" styles={styles}>
      <>
        <Header
          title={t("Preview")}
          text={t(
            "Look through the claim and make sure that the information is correct before submiting it."
          )}
          number={5}
          logoutOn={true}
        />
        <div className="outer-inner-wrapper">
          <div className="container container-dct p-t-half">
            <BackgroundBox>
              <div className="text-center">
                <label htmlFor="img">
                  {t("Accident sketch")}
                  {
                    <a href="#/sketch" className="text-primary">
                      <img src={editSvgImage} alt={t("edit sketch")}></img>
                      {t("Edit")}
                    </a>
                  }
                </label>
                <img
                  className="preview-image"
                  src={canvasImage}
                  alt={t("image preview")}
                ></img>
              </div>
            </BackgroundBox>

            <BackgroundBox>
              <label>
                {t("Description")}
                {
                  <a href="#/description" className="text-primary">
                    <img src={editSvgImage} alt={t("edit description")}></img>
                    {t("Edit")}
                  </a>
                }
              </label>
              <div className="text-lighten">
                <p>{textDescription}</p>
              </div>
            </BackgroundBox>
            <div
              className="submit-warning"
              data-am-alert="warning"
              style={{
                display: textDescription ? "none" : "block",
              }}
            >
              <span className="alert-icon">
                <i className="material-icon-warning"></i>
              </span>
              <span>{t("Please add a description before submitting.")}</span>
            </div>
            <div
              className="submit-warning"
              data-am-alert="warning"
              style={{
                display: canvasImage ? "none" : "block",
              }}
            >
              <span className="alert-icon">
                <i className="material-icon-warning"></i>
              </span>
              <span>{t("Please add a sketch before submitting.")}</span>
            </div>
          </div>
          <footer>
            <Buttons
              hrefBack="description"
              hrefNext="thankyou"
              isValidated={isValidated}
              runOnClick={(e) => {
                e.preventDefault();
                submit();
              }}
            />
          </footer>
        </div>
        {showErrorModal ? (
          <div data-am-modal role="dialog">
            <div className="modal-container">
              <div className="modal-dialog m-t m-b">
                <div className="close-modal-button-wrapper">
                  <button
                    className="btn-close-modal"
                    title={t("Close")}
                    onClick={() => setShowErrorModal(false)}
                  >
                    <i className="icon-cancel no-margin"></i>
                    <span className="sr-only">{t("Close")}</span>
                  </button>
                </div>
                <h1 className="m-t error-header" data-am-alert="error">
                  {t("Couldn't submit your information")}
                </h1>
                <Trans t={t} i18nKey="error submitting">
                  <p className="m-t text-bigger">
                    We are unfortunately unable to process the information you
                    sent.
                  </p>
                  <p className="m-t-half m-b text-bigger">
                    Please try submitting again after a minute.
                  </p>
                </Trans>
                <button
                  data-am-button="primary-login full-width"
                  className={"button"}
                  onClick={() => setShowErrorModal(false)}
                >
                  {t("Close")}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </>
    </Styled>
  );
}

export default Preview;
