import {
  cloneElement,
  useEffect,
  useState,
  ReactElement,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import Styled from "../Styled/Styled";
import styles from "./Steps.scss";

interface StepsProps {
  onFinalNext: () => void; // Callback that is executed when the Next button is clicked on the last step
  children?: React.ReactElement[];
  images?: string[];
}

const Steps = ({ onFinalNext, images, children }: StepsProps) => {
  const [visibleStepIndex, setVisibleStepIndex] = useState(0);
  const [childrenWithVisibility, setChildrenWithVisibility] = useState(
    [] as ReactElement[]
  );
  const { t } = useTranslation();

  const previous = useCallback(() => {
    setVisibleStepIndex(visibleStepIndex - 1);
  }, [visibleStepIndex, setVisibleStepIndex]);

  const isFinalStep = visibleStepIndex === (children?.length ?? 0) - 1;
  const next = useCallback(() => {
    if (isFinalStep) {
      if (onFinalNext) {
        onFinalNext();
      }
    } else {
      setVisibleStepIndex(visibleStepIndex + 1);
    }
  }, [visibleStepIndex, setVisibleStepIndex, onFinalNext, isFinalStep]);

  useEffect(() => {
    const clonedChildren = [] as ReactElement[];
    children?.forEach((child, i) => {
      clonedChildren.push(
        cloneElement(child, {
          key: i,
          className: "step" + (visibleStepIndex === i ? " is-visible" : ""),
        })
      );
    });

    setChildrenWithVisibility(clonedChildren);
  }, [children, visibleStepIndex]);

  return (
    <Styled styles={styles} className="steps-wrapper">
      <div className="steps">
        <div className="step-top">
          {images && <img src={images[visibleStepIndex]} alt="" />}
        </div>
        <p>
          {visibleStepIndex + 1}/{children?.length ?? 0}
        </p>
        <div className="steps-content">{childrenWithVisibility}</div>
        <div className="step-buttons m-t p-b">
          {visibleStepIndex > 0 ? (
            <button
              data-am-button="secondary-login"
              className="button m-r-half"
              onClick={previous}
            >
              {t("Go back")}
            </button>
          ) : null}
          <button
            data-am-button="primary-login full-width"
            className={"button"}
            onClick={next}
          >
            {isFinalStep ? t("Start sketching") : t("Next")}
          </button>
        </div>
      </div>
    </Styled>
  );
};

export default Steps;
