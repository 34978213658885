export function getFromLocalStorage(
  key: localStorageKey,
  id: string | undefined
) {
  return localStorage.getItem(`${key}_${id}`);
}

export function saveInLocalStorage(
  key: localStorageKey,
  id: string | undefined,
  itemToStore: string
) {
  localStorage.setItem(`${key}_${id}`, itemToStore);
}

export function removeFromLocalStorage(
  key: localStorageKey,
  id: string | undefined
) {
  return localStorage.removeItem(`${key}_${id}`);
}

export type localStorageKey = "textDescription" | "canvasJson" | "canvasImage";
