import Header from "../Header/Header";
import Buttons from "../Buttons/Buttons";
import { useTranslation } from "react-i18next";
import getTemplates from "../../services/templates";
import styles from "./ChooseTemplate.scss";
import Styled from "../Styled/Styled";
import { useState } from "react";

interface ChooseTemplateProps {
  updateSketch: (newTemplate: string | undefined) => void;
}

function ChooseTemplate({ updateSketch }: ChooseTemplateProps) {
  const { t } = useTranslation();

  const [selectedTemplate, setSelectedTemplate] = useState("");

  return (
    <Styled className="outer-wrapper" styles={styles}>
      <>
        <Header
          title={t("Road template")}
          text={t(
            "Choose a road template that best depicts the accident location."
          )}
          number={2}
          tooltipText={t("tooltip_template")}
          logoutOn={true}
        />
        <div className="container container-dct-small p-t">
          <div className="modal-container">
            <div data-am-accordion>
              <ul className="accordion-list" data-am-list>
                {getTemplates(t).map((templateGroup, index) => (
                  <div key={index}>
                    <li>
                      <input
                        type="checkbox"
                        name="accordion"
                        className="checkbox"
                        id={templateGroup.groupName}
                      />
                      <label
                        htmlFor={templateGroup.groupName}
                        className="accordion-header category-label"
                      >
                        {templateGroup.groupName}
                      </label>
                      <div className="accordion-body">
                        <div
                          data-am-content
                          className="accordion-container template"
                        >
                          {templateGroup.subGroups.map((subGroup, index) => (
                            <div key={index}>
                              <p className="subcategory-label">
                                {subGroup.subGroupName}
                              </p>
                              {subGroup.templates.map(
                                (template, innerIndex) => (
                                  <button
                                    onClick={() => {
                                      setSelectedTemplate(
                                        template.templateType
                                      );
                                      updateSketch(template.templateType);
                                    }}
                                    key={innerIndex}
                                  >
                                    <img
                                      className={
                                        template.templateType ===
                                        selectedTemplate
                                          ? "selected bg-primary-light"
                                          : ""
                                      }
                                      src={template.image}
                                      alt={template.name}
                                    ></img>
                                    <p
                                      className={
                                        template.templateType ===
                                        selectedTemplate
                                          ? "selected-text"
                                          : ""
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: template.name,
                                      }}
                                    ></p>
                                  </button>
                                )
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <footer>
          <Buttons
            hrefBack="language"
            /*Changed hrefBack from (claim) to (language) to hide claim details
              page. April 11, 2023. #782851*/
            hrefNext="sketch"
            isValidated={selectedTemplate !== ""}
          />
        </footer>
      </>
    </Styled>
  );
}

export default ChooseTemplate;
