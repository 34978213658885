import styles from "./ProgressTracker.css";
import { useTranslation } from "react-i18next";
import Styled from "../Styled/Styled";

interface ProgressProps {
  step: number;
}

function ProgressTracker({ step }: ProgressProps) {
  const { t } = useTranslation();

  return (
    <Styled styles={styles}>
      <>
        <progress
          className="progress-tracker"
          aria-label={t("Progress Tracker")}
          aria-valuemin={1}
          aria-valuemax={5}
          aria-valuenow={step}
          max="5"
          value={step}
        ></progress>
      </>
    </Styled>
  );
}

export default ProgressTracker;
