import { Stage, Layer, Transformer, Rect, Image } from "react-konva";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Buttons from "../Buttons/Buttons";
import SketchImage from "./SketchImage";
import Styled from "../Styled/Styled";
import styles from "./Sketch.css";
import { useTranslation } from "react-i18next";
import Konva from "konva";
import { IconImageProps, MaxCanvasSize, MinCanvasHeight } from "./Images";
import { getTemplateForCanvas } from "./GetTemplateAndImages";
import { getImagesForCanvas } from "./GetTemplateAndImages";
import { saveInLocalStorage } from "../../services/localStorage";
import { BrandContext, BrandState } from "../../contexts/brandContext";
import DeleteButton, { DeleteButtonRef } from "./DeleteButton";
import { useWindowDimension } from "../../hooks/useWindowDimension";
import rotateImageSvg from "../../img/rotate.svg";
import useImage from "use-image";
import ToolBarManager from "../ToolBarModal/ToolBarManager";
import StationaryToolBar from "../ToolBarModal/StationaryToolBar";
import Header from "../Header/Header";

interface SketchCanvasProps {
  templateType: string | undefined;
  idHash: string | undefined;
}

function SketchCanvas({ templateType, idHash }: SketchCanvasProps) {
  const { t } = useTranslation();
  const konvajsContentRef = useRef<HTMLDivElement>(null);
  const trRef = useRef<any>();
  const rotateRef = useRef<any>();
  const stageRef = useRef<any>();
  const deleteButtonRef = useRef<DeleteButtonRef>(null);
  const [rotateImg] = useImage(rotateImageSvg);
  const [windowWidth, windowHeight] = useWindowDimension();

  const [sketchImages, setSketchImages] = useState<React.ReactElement[]>([]);
  const [templateImage, setTemplateImage] = useState<React.ReactElement>();
  const isTest = process.env.JEST_WORKER_ID !== undefined;

  const brandSettings: BrandState = useContext(BrandContext);

  const [headerHeight, setHeaderHeight] = useState(0);
  const headerHeightRef = useCallback(
    (header: HTMLDivElement) => {
      if (header !== null && konvajsContentRef?.current && windowHeight > 0) {
        setHeaderHeight(header.clientHeight);
      }
    },
    [windowHeight, konvajsContentRef]
  );
  const [footerHeight, setFooterHeight] = useState(0);
  const footerHeightRef = useCallback(
    (footer: HTMLDivElement) => {
      if (footer !== null && konvajsContentRef?.current && windowHeight > 0) {
        setFooterHeight(footer.clientHeight);
      }
    },
    [windowHeight, konvajsContentRef]
  );

  const canvasSize = useMemo(() => {
    return Math.min(
      MaxCanvasSize,
      windowWidth,
      Math.max(MinCanvasHeight, windowHeight - headerHeight - footerHeight)
    );
  }, [windowWidth, windowHeight, headerHeight, footerHeight]);
  const scale = canvasSize / MaxCanvasSize;
  const templateXCoordinate = konvajsContentRef?.current
    ? konvajsContentRef.current?.clientWidth / 2 - canvasSize / 2
    : 0;

  /**
   * Moves our custom rotate icon based on the transformer.
   * The X and Y needs to take rotation into account,
   * hence the sinus and cosinus.
   *
   * (Math.PI / 180) converts degrees into radian
   * which is required for cos and sin.
   */
  const updateRotateIconPosition = useCallback(
    (overrideXMovement?: number) => {
      const rotateIcon = rotateRef.current;
      const transformer = trRef.current;
      const cosOfRotation = Math.cos(transformer.rotation() * (Math.PI / 180));
      const sinOfRotation = Math.sin(transformer.rotation() * (Math.PI / 180));
      const transformerX =
        transformer.x() - (overrideXMovement ?? templateXCoordinate);
      const transformerY = transformer.y();

      const distanceBetweenAnchorAndIcon = 60;
      const middleOfIcon = trRef.current.width() / 2;

      rotateIcon.rotation(trRef.current.rotation());
      rotateIcon.x(
        transformerX +
          middleOfIcon * cosOfRotation +
          distanceBetweenAnchorAndIcon * sinOfRotation
      );
      rotateIcon.y(
        transformerY +
          middleOfIcon * sinOfRotation -
          distanceBetweenAnchorAndIcon * cosOfRotation
      );
    },
    [templateXCoordinate]
  );

  const onSketchImageDragEnd = useCallback(() => {
    if (deleteButtonRef?.current?.onDrop) {
      deleteButtonRef.current.onDrop();
    }
    updateRotateIconPosition(
      konvajsContentRef?.current
        ? konvajsContentRef.current?.clientWidth / 2 - canvasSize / 2
        : 0
    );
  }, [deleteButtonRef, canvasSize, updateRotateIconPosition]);

  const onSketchImageDragMove = useCallback(
    (target: HTMLElement) => {
      if (deleteButtonRef?.current?.onDragMove) {
        deleteButtonRef.current.onDragMove(target);
      }
    },
    [deleteButtonRef]
  );

  const randomCenter = () => {
    const randomX = Math.random() * 40 - 20;
    const randomY = Math.random() * 40 - 20;
    const centerXY = canvasSize / 2;
    const randomCenterX = Math.floor(centerXY + randomX);
    const randomCenterY = Math.floor(centerXY + randomY);

    return [randomCenterX, randomCenterY];
  };

  const select = useCallback(
    (clickedRef: any) => {
      trRef.current.nodes([clickedRef.current]);
      updateRotateIconPosition(
        konvajsContentRef?.current
          ? konvajsContentRef.current?.clientWidth / 2 - canvasSize / 2
          : 0
      );
      rotateRef.current.opacity(1);
      trRef.current.getLayer().batchDraw();
    },
    [canvasSize, updateRotateIconPosition]
  );

  const addSketchImage = (icon: IconImageProps) => {
    addSketchImageWithPosition(randomCenter(), icon);
  };

  const addSketchImageWithPosition = (
    position: number[],
    icon: IconImageProps
  ) => {
    if (icon) {
      setSketchImages([
        ...sketchImages,
        <SketchImage
          image={icon.src}
          key={sketchImages.length + 1}
          onClick={select}
          onDragMove={onSketchImageDragMove}
          onDragEnd={onSketchImageDragEnd}
          position={position}
          rotation={0}
          scale={scale}
          id={Date.now().toString()}
          iconType={icon.iconType}
          width={icon.customWidth}
        />,
      ]);
    }
  };

  let draggedIcon: IconImageProps;
  const dragIcon = (icon: IconImageProps) => {
    draggedIcon = icon;
  };

  const deleteSketchImage = () => {
    const selected = trRef.current.nodes();
    if (selected.length > 0) {
      selected[0].destroy();
      deselect({ target: stageRef.current });
      trRef.current.forceUpdate();
    }
  };

  const saveCanvas = useCallback(
    (stage: Konva.Stage) => {
      const canvas = stage.toJSON();
      saveInLocalStorage("canvasJson", idHash, canvas);
    },
    [idHash]
  );

  const handleTransformerMoveAndTransform = () => {
    updateRotateIconPosition();
  };
  const handleTransformerHover = () => {
    stageRef.current.content.style.cursor = "default";
  };

  const saveCanvasAsImage = () => {
    deselect({ target: stageRef.current });
    saveCanvas(stageRef.current.getStage());
    const canvasImage = stageRef.current.toDataURL({
      pixelRatio: 1 / scale,
      x: templateXCoordinate,
      y: 0,
      width: canvasSize,
      height: canvasSize,
    });
    saveInLocalStorage("canvasImage", idHash, canvasImage);
  };

  const deselect = (evt: any) => {
    // Deselect image when clicked on empty area
    const clickedOnEmpty = evt.target === evt.target.getStage();
    if (clickedOnEmpty) {
      trRef.current.nodes([]);
      rotateRef.current.opacity(0);
    }
  };

  useEffect(() => {
    getTemplateForCanvas(setTemplateImage, templateType, canvasSize, t, idHash);
    getImagesForCanvas(
      setSketchImages,
      select,
      onSketchImageDragMove,
      onSketchImageDragEnd,
      scale,
      t,
      idHash
    );

    if (stageRef.current !== null) {
      const timer = setInterval(
        () => saveCanvas(stageRef.current.getStage()),
        5000
      );
      return () => {
        clearInterval(timer);
      };
    }
  }, [
    canvasSize,
    idHash,
    onSketchImageDragEnd,
    onSketchImageDragMove,
    saveCanvas,
    scale,
    select,
    t,
    templateType,
  ]);

  return (
    <>
      <div ref={headerHeightRef}>
        <Header
          title={t("Sketch the accident")}
          text={t(
            "Sketch the accident as detailed as possible by adding objects from the toolbar."
          )}
          textMobile={t(
            "Sketch the accident as detailed as possible. Use the Plus-icon to access the toolbar."
          )}
          number={3}
          logoutOn={true}
        />
      </div>
      <Styled className="outer-inner-wrapper" styles={styles}>
        <>
          <div className="bg-background-3">
            <div className="container canvas-wrapper text-center">
              <StationaryToolBar onClick={addSketchImage} onDrag={dragIcon} />
              {!isTest ? (
                <div
                  onDrop={(e) => {
                    e.preventDefault();
                    stageRef.current.setPointersPositions(e);
                    const { x, y } = stageRef.current.getPointerPosition();
                    addSketchImageWithPosition(
                      [x - templateXCoordinate, y],
                      draggedIcon
                    );
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  className="konvajs-content"
                  style={{
                    height: canvasSize,
                  }}
                  ref={konvajsContentRef}
                >
                  <DeleteButton
                    ref={deleteButtonRef}
                    deleteFn={deleteSketchImage}
                  ></DeleteButton>
                  <Stage
                    width={konvajsContentRef?.current?.clientWidth ?? 0}
                    height={canvasSize}
                    onClick={deselect}
                    ref={stageRef}
                  >
                    <Layer x={templateXCoordinate}>
                      <Rect
                        x={0}
                        y={0}
                        width={canvasSize}
                        height={canvasSize}
                        fill={brandSettings.canvasBackgroundColor}
                        listening={false}
                      />
                      {templateImage}
                    </Layer>
                    <Layer x={templateXCoordinate}>
                      {sketchImages}
                      <Transformer
                        resizeEnabled={false}
                        ref={trRef}
                        anchorCornerRadius={50}
                        anchorSize={20}
                        rotateAnchorOffset={60}
                        onDragMove={handleTransformerMoveAndTransform}
                        onTransform={handleTransformerMoveAndTransform}
                        onMouseEnter={handleTransformerHover}
                      />
                      <Image
                        image={rotateImg}
                        ref={rotateRef}
                        height={20}
                        width={20}
                        offsetX={10}
                        offsetY={10}
                        listening={false}
                        opacity={0}
                      />
                    </Layer>
                  </Stage>
                </div>
              ) : null}
            </div>
          </div>
          <footer ref={footerHeightRef}>
            <Buttons
              hrefBack="template"
              hrefNext="description"
              isValidated={true}
              runOnClick={saveCanvasAsImage}
            >
              <ToolBarManager onClick={addSketchImage} onDrag={dragIcon} />
            </Buttons>
          </footer>
        </>
      </Styled>
    </>
  );
}

export default SketchCanvas;
