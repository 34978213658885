import { useCallback, useEffect, useMemo, useState } from "react";
import Steps from "../Steps/Steps";
import intro from "../../img/examples/sunset-intro.jpg";
import exampleOne from "../../img/examples/example1.svg";
import exampleTwo from "../../img/examples/example2.svg";
import exampleThree from "../../img/examples/example3.svg";
import exampleFour from "../../img/examples/example4.svg";
import { Trans, useTranslation } from "react-i18next";
import Styled from "../Styled/Styled";
import styles from "./InstructionPopup.scss";

const InstructionPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const sessionStorageKey = "instructionsShown";

  const stepImages = useMemo(
    () => [intro, exampleOne, exampleTwo, exampleThree, exampleFour],
    []
  );

  const close = useCallback(() => {
    setIsOpen(false);
    sessionStorage.setItem(sessionStorageKey, "true");
  }, [setIsOpen]);

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  useEffect(() => {
    // This tells the browser to pre-load the images
    stepImages.forEach((image, i) => {
      let preloadImage = new Image();
      preloadImage.src = image;
      (window as { [key: string]: any })[`preLoaded_${i}`] = preloadImage;
    });

    // Check whether instructions were already shown during this session. If not, open the tooltip modal.
    const sessionStorageItem = sessionStorage.getItem(sessionStorageKey);
    if (!sessionStorageItem) {
      setIsOpen(true);
    }
  }, [stepImages]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <Styled styles={styles}>
      <>
        <button type="button" onClick={open} data-am-link="underline">
          <span className="btn-icon">
            <span className="material-icon-info-outline instruction-icon">
              <span className="sr-only">{t("Open instructions")}</span>
            </span>
          </span>
          <span>{t("I need help")}</span>
        </button>

        {isOpen ? (
          <div data-am-modal role="dialog">
            <div className="modal-container">
              <div className="modal-dialog instruction-modal m-t-sm m-b-sm">
                <div className="close-modal-button-wrapper">
                  <button
                    className="btn-close-modal"
                    title={t("Close")}
                    onClick={close}
                  >
                    <i className="icon-cancel no-margin"></i>
                    <span className="sr-only">{t("Close")}</span>
                  </button>
                </div>
                <Steps onFinalNext={close} images={stepImages}>
                  <div data-testid="instruction-step">
                    <Trans t={t} i18nKey="instructions step 1">
                      <h3>Some tips before you start creating the sketch</h3>
                      <p>
                        We would like you to give you some tips before you start
                        creating the sketch to ensure that it's as easy as
                        possible while we get all the information we need.
                      </p>
                      <div data-am-alert="info">
                        <span className="alert-icon">
                          <i className="material-icon-info"></i>
                        </span>
                        <span className="alert-message">
                          You can always open this guide by pressing the link in
                          the header.
                        </span>
                      </div>
                    </Trans>
                  </div>
                  <div data-testid="instruction-step">
                    <Trans t={t} i18nKey="instructions step 2">
                      <h3>Move, rotate and delete</h3>
                      <p>
                        Move and rotate an object by first clicking on it. Once
                        it's selected you can then drag it to a new location.
                        You can rotate an object by dragging in the small filled
                        square.
                      </p>
                      <p>
                        Remove an object by first selecting it. Then click on
                        the trashcan.
                      </p>
                    </Trans>
                  </div>
                  <div data-testid="instruction-step">
                    <Trans t={t} i18nKey="instructions step 3">
                      <h3>Direction of travel</h3>
                      <p>
                        Indicate where you were coming from and where you were
                        going before the accident happened, also indicate the
                        origin of other relevant vehicles. You do this by
                        placing arrows on the road.
                      </p>
                    </Trans>
                  </div>
                  <div data-testid="instruction-step">
                    <Trans t={t} i18nKey="instructions step 4">
                      <h3>Lane change</h3>
                      <p>
                        If the road had multiple lanes, then it's important for
                        us to know which lane you were in and if you or any
                        other involved vehicles did a lane change. Use arrows to
                        indicate the lane you were in, and then the "lane
                        change" arrow to indicate lane changes.
                      </p>
                    </Trans>
                  </div>
                  <div data-testid="instruction-step">
                    <Trans t={t} i18nKey="instructions step 5">
                      <h3>Collision</h3>
                      <p>Mark where the collision happened.</p>
                    </Trans>
                  </div>
                </Steps>
              </div>
            </div>
          </div>
        ) : null}
      </>
    </Styled>
  );
};

export default InstructionPopup;
