import Header from "../Header/Header";
import Buttons from "../Buttons/Buttons";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import {
  getFromLocalStorage,
  saveInLocalStorage,
} from "../../services/localStorage";
import styles from "./Description.css";
import Styled from "../Styled/Styled";
import BackgroundBox from "../BackgroundBox/BackgroundBox";

interface DescriptionProps {
  idHash: string | undefined;
}

function Description({ idHash }: DescriptionProps) {
  const { t } = useTranslation();

  const [description, setDescription] = useState<string>();
  const [descriptionCount, setDescriptionCount] = useState(1000);
  const [isValidated, setIsValidated] = useState(false);
  const [descriptionTooLong, setDescriptionTooLong] = useState(false);

  const displayErrorIfDesctiptionTooLong = () => {
    if (descriptionCount === 0) {
      setDescriptionTooLong(true);
    }
  };

  const canvasImage = getFromLocalStorage("canvasImage", idHash)?.toString();

  useEffect(() => {
    const textDescription = getFromLocalStorage("textDescription", idHash);
    if (textDescription !== null) {
      setDescription(textDescription);
    }
  }, [idHash]);

  useEffect(() => {
    if (description && description.length >= 1) {
      setIsValidated(true);
      setDescriptionCount(1000 - description.length);
    } else {
      setIsValidated(false);
    }

    if (description) {
      saveInLocalStorage("textDescription", idHash, description);
    }
  }, [description, idHash]);

  return (
    <Styled className="outer-wrapper" styles={styles}>
      <>
        <Header
          title={t("Describe the accident")}
          text={t("Describe the sketch.")}
          number={4}
          tooltipText={t("tooltip_description")}
          logoutOn={true}
        />
        <div className="outer-inner-wrapper">
          <div className="container container-dct p-t-half">
            <BackgroundBox>
              <div className="text-center">
                <label htmlFor="img">{t("Accident sketch")}</label>
                <img
                  className="preview-image"
                  src={canvasImage}
                  alt={t("image preview")}
                ></img>
              </div>
            </BackgroundBox>
            <div data-am-alert="info" className="m-t-half">
              <span className="alert-icon">
                <i className="material-icon-info"></i>
              </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: t(
                    "Do not add information about any personal injuries or registration numbers in the description."
                  ),
                }}
              ></span>
            </div>
            <BackgroundBox>
              <label htmlFor="textarea">{t("Description")}</label>
              <div data-am-inputtext>
                <textarea
                  id="textarea"
                  data-testid="textarea"
                  className={
                    "text-description" +
                    (descriptionTooLong
                      ? " message-error input-validation-error"
                      : "")
                  }
                  maxLength={1000}
                  required
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setDescriptionTooLong(false);
                  }}
                  value={description}
                  onKeyDown={displayErrorIfDesctiptionTooLong}
                ></textarea>
              </div>
            </BackgroundBox>
            <div className="m-t-half">
              <span>{t("Characters left: ") + descriptionCount}</span>
              <div
                data-am-alert="warning"
                className={"m-t-half" + (descriptionTooLong ? "" : " hide")}
              >
                <span className="alert-icon">
                  <i className="material-icon-warning"></i>
                </span>
                <span>{t("The maximum amount of characters is reached.")}</span>
              </div>
            </div>
          </div>
          <footer>
            <Buttons
              hrefBack="sketch"
              hrefNext="preview"
              isValidated={isValidated}
            />
          </footer>
        </div>
      </>
    </Styled>
  );
}

export default Description;
