import bicycleRoundabout from "../img/templates/bicycle-road-next-to-roundabout.svg";
import exitFromParking from "../img/templates/exit-from-parking-lot.svg";
import fourWay from "../img/templates/four-way-single-lanes.svg";
import fourWay2 from "../img/templates/four-way.svg";
import fourWay4_3 from "../img/templates/four-way-4_3-lanes.svg";
import fourWay4 from "../img/templates/four-way-4-lanes.svg";
import interweaving from "../img/templates/interweaving.svg";
import oneLaneWithBicycleCrossing from "../img/templates/one-lane-road-with-bicycle-crossing.svg";
import oneLaneWithParking from "../img/templates/one-lane-road-with-parking.svg";
import oneLane from "../img/templates/one-lane-road.svg";
import parking from "../img/templates/parking.svg";
import roundabout4Double from "../img/templates/roundabout-4-exits-2-lanes.svg";
import roundabout4 from "../img/templates/roundabout-4-exits-2-lanes-no-lines.svg";
import roundabout3Double from "../img/templates/roundabout-3-exits-2-lanes.svg";
import roundabout3 from "../img/templates/roundabout-3-exits-2-lanes-no-lines.svg";
import threeLane from "../img/templates/three-lane-road.svg";
import threeWayIntersection from "../img/templates/three-way-intersection-single-lanes.svg";
import threeWayIntersectionDouble from "../img/templates/three-way-intersection-two-lanes.svg";
import threeWayIntersectionBicycle from "../img/templates/three-way-intersection-with-bicycle.svg";
import twoLane from "../img/templates/two-lane-road.svg";
import empty from "../img/templates/empty.svg";
import { TFunction } from "react-i18next";

function getTemplates(t: TFunction) {
  return [
    {
      groupName: t("Roundabouts"),
      subGroups: [
        {
          subGroupName: t("4 exits"),
          templates: [
            {
              image: roundabout4,
              name: t("Roundabout"),
              templateType: "roundaboutSingle",
            },
            {
              image: roundabout4Double,
              name: t("Roundabout two lanes"),
              templateType: "roundaboutDouble",
            },
          ],
        },
        {
          subGroupName: t("3 exits"),
          templates: [
            {
              image: roundabout3,
              name: t("Roundabout"),
              templateType: "roundabout3Single",
            },
            {
              image: roundabout3Double,
              name: t("Roundabout two lanes"),
              templateType: "roundabout3Double",
            },
          ],
        },
      ],
    },
    {
      groupName: t("Intersections"),
      subGroups: [
        {
          subGroupName: t("Four way"),
          templates: [
            {
              image: fourWay,
              name: t("Four way road"),
              templateType: "fourWaySingle",
            },
            {
              image: fourWay2,
              name: t("Two lanes"),
              templateType: "fourWay",
            },
            {
              image: fourWay4_3,
              name: t("Four lanes meet three"),
              templateType: "fourWay4_3",
            },
            {
              image: fourWay4,
              name: t("Four lanes"),
              templateType: "fourWay4",
            },
          ],
        },
        {
          subGroupName: t("T-junctions"),
          templates: [
            {
              image: threeWayIntersection,
              name: t("T-junction"),
              templateType: "threeWayIntersection",
            },
            {
              image: threeWayIntersectionDouble,
              name: t("T-junction multiple lanes"),
              templateType: "threeWayIntersectionDouble",
            },
          ],
        },
      ],
    },
    {
      groupName: t("Bicycle crossing"),
      subGroups: [
        {
          subGroupName: "",
          templates: [
            {
              image: oneLaneWithBicycleCrossing,
              name: t("Road with bicycle crossing"),
              templateType: "oneLaneWithBicycleCrossing",
            },
            {
              image: threeWayIntersectionBicycle,
              name: t("T-junction with bicycle crossing"),
              templateType: "threeWayIntersectionBicycle",
            },
            {
              image: bicycleRoundabout,
              name: t("Roundabout with bicycle crossing"),
              templateType: "bicycleRoundabout",
            },
          ],
        },
      ],
    },
    {
      groupName: t("Parking"),
      subGroups: [
        {
          subGroupName: "",
          templates: [
            {
              image: parking,
              name: t("Parking area"),
              templateType: "parking",
            },
            {
              image: exitFromParking,
              name: t("Exit from parking"),
              templateType: "exitFromParking",
            },
            {
              image: oneLaneWithParking,
              name: t("Road with parking"),
              templateType: "oneLaneWithParking2",
            },
          ],
        },
      ],
    },
    {
      groupName: t("Other"),
      subGroups: [
        {
          subGroupName: "",
          templates: [
            {
              image: interweaving,
              name: t("Interweaving"),
              templateType: "interweaving",
            },
            {
              image: oneLaneWithParking,
              name: t("Road with parking"),
              templateType: "oneLaneWithParking",
            },
            {
              image: oneLane,
              name: t("Simple road"),
              templateType: "oneLane",
            },
            {
              image: twoLane,
              name: t("Two lanes"),
              templateType: "twoLane",
            },
            {
              image: threeLane,
              name: t("Three lanes"),
              templateType: "threeLane",
            },
            {
              image: empty,
              name: t("Empty template"),
              templateType: "empty",
            },
          ],
        },
      ],
    },
  ];
}

export default getTemplates;
