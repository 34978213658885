import styles from "./RadioButton.scss";
import Styled from "../Styled/Styled";

interface RadioButtonProps {
  value: string;
  label: string;
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function RadioButton({ value, label, isChecked, onChange }: RadioButtonProps) {
  return (
    <Styled styles={styles}>
      <div className="radio-wrapper">
        <div data-am-inputselection="radio light">
          <input
            id={`${label}-radio`}
            type="radio"
            name="language"
            value={value}
            checked={isChecked}
            onChange={onChange}
          />
          <label htmlFor={`${label}-radio`}>
            <span className="text text-primary">{label}</span>
          </label>
        </div>
      </div>
    </Styled>
  );
}

export default RadioButton;
