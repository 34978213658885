import { useState } from "react";
import Styled from "../Styled/Styled";
import styles from "./Tooltip.css";

interface TooltipProps {
  tooltipText: string;
}

function Tooltip({ tooltipText }: TooltipProps) {
  const [isActive, setActive] = useState(false);

  const toggleTooltip = () => {
    setActive(!isActive);
  };

  return (
    <Styled styles={styles}>
      <div
        data-testid="tooltip-test"
        data-am-tooltip="light-color"
        className={isActive ? "is-open" : ""}
      >
        <button
          data-testid="tooltip-open"
          className="tooltip-btn"
          onClick={toggleTooltip}
        >
          <span className="btn-icon">
            <span className="material-icon-help-outline tooltip-icon text-primary"></span>
          </span>
        </button>
        <div className="tooltip">
          <div className="tooltip-content">
            <div data-am-content>
              <p>{tooltipText}</p>
            </div>
          </div>
          <button className="tooltip-close-btn" onClick={toggleTooltip}>
            <i className="icon-cancel no-margin"></i>
          </button>
        </div>
        <div className="ui-block" onClick={toggleTooltip}></div>
      </div>
    </Styled>
  );
}

export default Tooltip;
