import { useTranslation } from "react-i18next";
import styles from "./Buttons.scss";
import Styled from "../Styled/Styled";
import ScrollToTopLink from "../ScrollToTopLink/ScrollToTopLink";

interface ButtonsProps {
  hrefBack?: string;
  hrefNext: string;
  children?: JSX.Element | JSX.Element[];
  isValidated: boolean;
  runOnClick?: (e: React.SyntheticEvent) => void;
}

function Buttons({
  hrefBack,
  hrefNext,
  children,
  isValidated,
  runOnClick,
}: ButtonsProps) {
  const { t } = useTranslation();

  return (
    <Styled styles={styles}>
      <div className="buttons text-center buttons-wrapper">
        {hrefBack ? (
          <ScrollToTopLink
            type="button"
            data-am-button="secondary-login"
            className="button back-button"
            to={hrefBack}
          >
            {t("Go back")}
          </ScrollToTopLink>
        ) : null}
        {children}
        {isValidated ? (
          <ScrollToTopLink
            type="button"
            data-testid="next-button"
            data-am-button="primary-login"
            className="wide button"
            to={hrefNext}
            onClick={runOnClick ? (e) => runOnClick(e) : undefined}
          >
            {hrefNext === "thankyou" ? t("Submit") : t("Next")}
          </ScrollToTopLink>
        ) : (
          <button
            data-testid="disabled-button"
            data-am-button="primary-login"
            className="wide button"
            disabled
          >
            {hrefNext === "thankyou" ? t("Submit") : t("Next")}
          </button>
        )}
      </div>
    </Styled>
  );
}

export default Buttons;
