import { useTranslation } from "react-i18next";
import { useState } from "react";
import styles from "./ChooseLanguage.css";
import Styled from "../Styled/Styled";
import Header from "../Header/Header";
import Buttons from "../Buttons/Buttons";
import RadioButton from "../RadioButton/RadioButton";

function ChooseLanguage() {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const [language, setLanguage] = useState(currentLanguage);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Styled className="outer-wrapper" styles={styles}>
      <>
        <Header
          title={t("Language settings")}
          text={""}
          number={1}
          logoutOn={true}
        />
        <div className="container container-dct-small p-t-half radio-container">
          <RadioButton
            value="sv"
            label="Svenska"
            isChecked={language === "sv"}
            onChange={handleChange}
          />
          <RadioButton
            value="en"
            label="English"
            isChecked={language === "en"}
            onChange={handleChange}
          />
        </div>
        <footer>
          <Buttons hrefNext="template" isValidated={true} />
          {/*Changed hrefNext from (claim) to (template) to hide claim details
            page. April 11, 2023. #782851*/}
        </footer>
      </>
    </Styled>
  );
}

export default ChooseLanguage;
