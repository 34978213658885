import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as apiClient from "../../services/api-client";
import styles from "./DigitalCompletionTool.css";
import Styled from "../Styled/Styled";
import ChooseLanguage from "../ChooseLanguage/ChooseLanguage";
import ChooseTemplate from "../ChooseTemplate/ChooseTemplate";
import Description from "../Description/Description";
import Preview from "../Preview/Preview";
import SketchAccident from "../SketchAccident/SketchAccident";
import ThankYou from "../ThankYou/ThankYou";
import Welcome from "../Welcome/Welcome";
import FullscreenSpinner from "../FullscreenSpinner/FullscreenSpinner";
import ErrorClaim from "../ErrorClaim/ErrorClaim";
import { BrandContext } from "../../contexts/brandContext";
import loginBackground from "../../img/decorative/volvia-login.jpg";

function DigitalCompletionTool() {
  const [selectedTemplate, setSelectedTemplate] = useState<string>();
  const [idHash, setIdHash] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [acrValues, setAcrValues] = useState([] as string[]);
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    var id = searchParams.get("id");
    if (id) {
      sessionStorage.setItem("id", id);
      setSearchParams("");
      setIsError(false);
      setIsLoading(true);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (isLoading) {
      apiClient
        .fetchUserId()
        .then((value) => {
          setAcrValues(value.acrValues);
          if (value.userId) {
            setIdHash(value.userId);
          } else {
            setIsLoading(false);
            return;
          }

          var id = sessionStorage.getItem("id");
          apiClient.checkId(id).then((idIsOk) => {
            setIsLoading(false);
            setIsError(!idIsOk);
          });
        })
        .catch(() => {
          setIsLoading(false);
          setIsError(true);
        });
    }
  }, [isLoading, setIsLoading]);

  function CheckUrl(path: string) {
    if (isLoading) {
      return <FullscreenSpinner />;
    }

    if (isError) {
      return <ErrorClaim />;
    }

    if (path !== "/welcome" && !idHash && !isLoading) {
      navigate("/welcome", { state: path });
    }
    if (path.match(/template\/?$/)) {
      return (
        <ChooseTemplate
          updateSketch={(newTemplate: string | undefined) =>
            setSelectedTemplate(newTemplate)
          }
        />
      );
    } else if (path.match(/sketch\/?$/)) {
      if (idHash) {
        return (
          <SketchAccident templateType={selectedTemplate} idHash={idHash} />
        );
      }
    } else if (path.match(/description\/?$/)) {
      if (idHash) {
        return <Description idHash={idHash} />;
      }
    } else if (path.match(/preview\/?$/)) {
      return <Preview idHash={idHash} />;
    } else if (path.match(/thankyou\/?$/)) {
      return <ThankYou />;
    } /*else if (path.match(/claim\/?$/)) {
      return <ClaimInformation claim={claim} />; //Commented out to hide claim details page. April 11, 2023. #782851
    }*/ else if (path.match(/language\/?$/)) {
      return <ChooseLanguage />;
    } else if (path.match(/relogin\/?$/)) {
      return (
        <Welcome
          redirectUri={state}
          isAuthenticated={false}
          acrValues={acrValues}
          isRelogin={true}
        />
      );
    } else {
      if (!state || state === "/") {
        return (
          <Welcome
            redirectUri="/language"
            isAuthenticated={idHash != null}
            acrValues={acrValues}
          />
        );
      }
      return (
        <Welcome
          redirectUri={state}
          isAuthenticated={idHash != null}
          acrValues={acrValues}
        />
      );
    }
  }

  return (
    <BrandContext.Provider
      value={{
        canvasBackgroundColor: "#DDE4E6",
        brandName: "Volvia",
        backgroundImage: loginBackground,
      }}
    >
      <Styled styles={styles}>
        <div>
          <main>{CheckUrl(pathname)}</main>
        </div>
      </Styled>
    </BrandContext.Provider>
  );
}

export default DigitalCompletionTool;
