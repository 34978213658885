import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import Pseudo from "i18next-pseudo";

console.warn(process.env.REACT_APP_PSEUDO);

i18n
  .use(new Pseudo({ enabled: Boolean(process.env.NODE_ENV !== "production") }))
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`../../lang/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    })
  )
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    postProcess: ["pseudo"],
  });

export default i18n;
