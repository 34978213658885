import styles from "./ErrorClaim.css";
import { Trans, useTranslation } from "react-i18next";
import volviaLogo from "../../img/decorative/volvia-logo.svg";
import Styled from "../Styled/Styled";

function ErrorClaim() {
  const { t } = useTranslation();

  return (
    <Styled styles={styles}>
      <>
        <div className="error-container text-center p-y-half">
          <img className="p-y-half" src={volviaLogo} alt="volvia logo"></img>
          <h1 className="p-t-half error-header" data-am-alert="error">
            {t("Couldn't load your claim")}
          </h1>
          <Trans t={t} i18nKey="error claim missing">
            <p className="p-t-half text-bigger">
              We are unfortunately unable to load your claim information.
            </p>
            <p className="p-t-half text-bigger">
              Please try again by opening the link directly from the email or
              sms where you received it.
            </p>
          </Trans>
        </div>
      </>
    </Styled>
  );
}

export default ErrorClaim;
