import styles from "./ThankYou.css";
import { useTranslation } from "react-i18next";
import volviaLogo from "../../img/decorative/volvia-logo.svg";
import Styled from "../Styled/Styled";

function ThankYou() {
  const { t } = useTranslation();

  return (
    <Styled styles={styles}>
      <>
        <div className="container container-dct-small thank-you-container text-center p-y-half">
          <img className="p-y-half" src={volviaLogo} alt="volvia logo"></img>
          <h1 className="p-t-half thank-you-header">{t("Thank you.")}</h1>
          <p className="p-t-half text-bigger">
            {t(
              "We have registered your claim and will get back to you as soon as possible."
            )}
          </p>
        </div>
      </>
    </Styled>
  );
}

export default ThankYou;
