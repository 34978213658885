import { Trans, useTranslation } from "react-i18next";
import Styled from "../Styled/Styled";
import WelcomeButton from "../WelcomeButton/WelcomeButton";
import bankIdIcon from "../../img/decorative/BankID_logo_white.svg";
import volviaLogo from "../../img/decorative/volvia-logo.svg";
import styles from "./Welcome.css";
import { BrandContext, BrandState } from "../../contexts/brandContext";
import { useContext } from "react";

interface WelcomePageProps {
  redirectUri: string;
  isAuthenticated: boolean;
  acrValues: string[];
  isRelogin?: boolean;
}

function Welcome(props: WelcomePageProps) {
  const { t, i18n } = useTranslation();
  const brandSettings: BrandState = useContext(BrandContext);

  const reloginContent = (
    <div className="relogin-content">
      <h3>{t("Your session has expired.")}</h3>
      <p>{t("Please log in again and finalize your work.")}</p>
    </div>
  );

  return (
    <Styled styles={styles}>
      <>
        <div
          className="welcome-main"
          style={{
            backgroundImage: `url(${brandSettings.backgroundImage})`,
          }}
        >
          <div className="welcome-box">
            <div className="welcome-text">
              <img src={volviaLogo} alt="volvia logo"></img>
              {props.isRelogin ? (
                reloginContent
              ) : (
                <>
                  <h4>{t("Create your completion in four easy steps")}</h4>
                  <div className="steps-list">
                    <ul>
                      <li>{t("Choose a road template")}</li>
                      <li>{t("Sketch the incident")}</li>
                      <li>{t("Describe in words")}</li>
                      <li>{t("Review and submit")}</li>
                    </ul>
                  </div>
                </>
              )}
            </div>
            <div className="welcome-auth">
              {props.isAuthenticated ? (
                <WelcomeButton
                  buttonText={t("Start")}
                  url={"#/language"}
                  buttonClass="primary"
                />
              ) : (
                <>
                  {props.acrValues.includes(
                    "urn:se:curity:authentication:oidc:bank-se"
                  ) ? (
                    <>
                      <div className="desktop-only">
                        <WelcomeButton
                          buttonText={t("Log in with mobile BankID")}
                          url={`/login?method=urn:oidc:method:sbid-qr&redirect=%23${props.redirectUri}&acr=urn:se:curity:authentication:oidc:bank-se&language=${i18n.language}`}
                          buttonIcon={bankIdIcon}
                          buttonClass="primary with-icon"
                        />
                        <WelcomeButton
                          buttonText={t("BankID on this device")}
                          url={`/login?redirect=%23${props.redirectUri}&acr=urn:se:curity:authentication:oidc:bank-se&language=${i18n.language}`}
                          buttonClass="secondary-login"
                        />
                      </div>
                      <div className="phone-only">
                        <WelcomeButton
                          buttonText={t("Log in with mobile BankID")}
                          url={`/login?method=urn:oidc:method:sbid&redirect=%23${props.redirectUri}&acr=urn:se:curity:authentication:oidc:bank-se&language=${i18n.language}`}
                          buttonIcon={bankIdIcon}
                          buttonClass="primary with-icon"
                        />
                      </div>
                    </>
                  ) : null}
                  {props.acrValues.includes(
                    "urn:se:curity:authentication:html-form:username-password"
                  ) ? (
                    <WelcomeButton
                      buttonText={t("Username + password")}
                      url={`/login?redirect=%23${props.redirectUri}&acr=urn:se:curity:authentication:html-form:username-password`}
                      buttonClass="secondary-login"
                    />
                  ) : null}
                </>
              )}
            </div>
            <p>
              <Trans t={t} i18nKey="privacy policy paragraph">
                You can find our{" "}
                <a
                  target="_blank"
                  href={t("privacyPolicyUrl")}
                  rel="noreferrer"
                >
                  privacy policy
                </a>{" "}
                here
              </Trans>
            </p>
          </div>
        </div>
      </>
    </Styled>
  );
}

export default Welcome;
