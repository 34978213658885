import { Link, LinkProps, useLocation } from "react-router-dom";
import { useEffect } from "react";

function ScrollToTopLink(props: LinkProps) {
  const { pathname } = useLocation();

  // I use the return function of useEffect to
  // only scrollTo when the button is unmounted
  // also check so the pathname changed so that
  // we don't scroll to top whenever a button
  // is disabled.
  // (aka: scroll when we go to a new page)
  useEffect(() => {
    return () => {
      let buttonPage = pathname;
      let currentPage = window.location.hash.replace("#", "");
      if (buttonPage !== currentPage) {
        window.scrollTo(0, 0);
      }
    };
  }, [pathname]);

  return <Link {...props} />;
}

export default ScrollToTopLink;
