import { KonvaEventObject } from "konva/lib/Node";
import { useEffect, useMemo, useRef } from "react";
import { Image } from "react-konva";
import useImage from "use-image";
import { TemplateImageName } from "./Images";

interface SketchImageProps {
  image: any;
  onClick: Function;
  onDragMove: (target: HTMLElement) => void;
  onDragEnd: () => void;
  position: number[];
  rotation: number;
  scale: number;
  id: string;
  iconType: string;
  width?: number;
}

function SketchImage({
  image,
  onClick,
  onDragMove,
  onDragEnd,
  position,
  rotation,
  scale,
  id,
  iconType,
  width,
}: SketchImageProps) {
  useEffect(() => {
    onClick(imageRef);
  }, [onClick]);

  const imageRef = useRef<any>(null);
  const [img] = useImage(image);

  const diffBetweenWidthAndHeight = useMemo(
    () => scale * (width ?? 80) - scale * 80,
    [scale, width]
  );

  const toRad = (degrees: number) => {
    return (degrees * 2 * Math.PI) / 360;
  };

  const onDragHandler = (evt: KonvaEventObject<MouseEvent | TouchEvent>) => {
    const stage = evt.target.getStage()?.attrs;
    const draggedImage = evt.target;
    const currentRotation = draggedImage.rotation();
    const currentX = draggedImage.x();
    const currentY = draggedImage.y();
    const boundaryXRight =
      stage.width - (draggedImage.parent?.getAbsolutePosition()?.x ?? 0);

    if (window.MouseEvent && evt.evt instanceof MouseEvent) {
      onDragMove(evt.evt.composedPath()[0] as HTMLElement);
    } else if (
      window.TouchEvent &&
      evt.evt instanceof TouchEvent &&
      evt.evt.targetTouches.length > 0
    ) {
      const touch = evt.evt.targetTouches[0];
      const root = ((evt.evt.target as HTMLElement).shadowRoot ||
        document) as DocumentOrShadowRoot;
      const target = root.elementFromPoint(touch.clientX, touch.clientY);
      onDragMove(target as HTMLElement);
    }

    const rotationModifier =
      (diffBetweenWidthAndHeight * Math.sin(toRad(currentRotation ?? 0))) / 2;

    // Limit X and Y within the canvas (should be visible)
    draggedImage.x(
      currentX - draggedImage.offsetX() - rotationModifier < 0
        ? draggedImage.offsetX() + rotationModifier
        : currentX + draggedImage.offsetX() + rotationModifier > boundaryXRight
        ? boundaryXRight - draggedImage.offsetX() - rotationModifier
        : currentX
    );

    draggedImage.y(
      currentY - draggedImage.offsetY() + rotationModifier < 0
        ? draggedImage.offsetY() - rotationModifier
        : currentY + draggedImage.offsetY() - rotationModifier > stage.height
        ? stage.height - draggedImage.offsetY() + rotationModifier
        : currentY
    );
  };

  const onDragEndHandler = (evt: KonvaEventObject<DragEvent>) => {
    const stage = evt.target.getStage();
    const template = stage?.findOne(`.${TemplateImageName}`);

    if (!template?.attrs) {
      onDragEnd();
      return;
    }

    const draggedImage = evt.target;
    const currentRotation = draggedImage.rotation();
    const currentX = draggedImage.x();
    const boundaryXRight = template.attrs.width;

    const rotationModifier =
      (diffBetweenWidthAndHeight * Math.sin(toRad(currentRotation))) / 2;

    // Limit X within the right edge of the template (as opposed to canvas)
    draggedImage.x(
      currentX + draggedImage.offsetX() + rotationModifier > boundaryXRight
        ? boundaryXRight - draggedImage.offsetY() - rotationModifier
        : currentX
    );
    onDragEnd();
  };

  return (
    <Image
      image={img}
      draggable
      onClick={() => {
        onClick(imageRef);
      }}
      onMouseDown={() => {
        onClick(imageRef);
      }}
      onTouchStart={() => {
        onClick(imageRef);
      }}
      onDragMove={onDragHandler}
      onDragEnd={onDragEndHandler}
      width={scale * (width ?? 80)}
      height={scale * 80}
      offsetX={(scale * (width ?? 80)) / 2}
      offsetY={(scale * 80) / 2}
      x={position[0]}
      y={position[1]}
      rotation={rotation}
      ref={imageRef}
      id={id}
      iconType={iconType}
    />
  );
}

export default SketchImage;
